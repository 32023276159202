import {
  Modal,
  Button,
  Backdrop,
} from "@mui/material";
import { ModalRoot, ContentModal, FooterActions } from "../../views/TariffProcess/styled.components";
import { Loader } from "../Base/Loader";
import CustomButton from "../CustomButtonStyled/CustomButton";

export const CustomModal = ({ title, open, isLoading, labelAccept, close, onSuccess, children, width, maxWidth }) => {

  return (
    <>
      <Modal open={open} onClose={close}>
        <ModalRoot sx={{ width: width || "auto", maxWidth: maxWidth || "100%" }}>
          <h3>{title}</h3>
          <ContentModal>
            {children}
          </ContentModal>
          <FooterActions>
            <Button variant="outlined" color="error" onClick={close}>
              Cancelar
            </Button>
            <CustomButton
              variant="contained"
              onClick={onSuccess}
              disabled={isLoading}
            >
              {labelAccept}
            </CustomButton>
          </FooterActions>
        </ModalRoot>
      </Modal>
      <Backdrop style={{ zIndex: 10000, color: 'white', textAlign: "center" }} open={isLoading}>
        <Loader size={'30px'} />
      </Backdrop>
    </>
  );
};
