import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { styled as styledMaterial } from "@mui/material/styles";
import styled from "styled-components";
import { ReactComponent as IconoReg } from '../../icons/icono_reg.svg';
import { ReactComponent as IconoNext } from '../../icons/icono_sig.svg';

const StyledTableContainer = styledMaterial(TableContainer)(({ theme }) => ({
  borderRadius: "10px",
  overflow: "hidden",
  border: "1px solid #3959A5",
  backgroundColor: "white",
}));

const CustomTableCell = styledMaterial(TableCell)(({ theme }) => ({
  backgroundColor: "#3959A5",
  color: "white",
  fontWeight: "bold",
  textAlign: "center",
}));

const CustomTableCellBody = styledMaterial(TableCell)(({ theme }) => ({
  textAlign: "center",
  padding: "8px",
  borderBottom: `1px solid #496FEF`,
}));

const TitleTableCell = styledMaterial(TableCell)`
  text-align: center;
  font-weight: bold;
  background-color: #3959A5;
  color: white;
  position: relative;
`;

const IconButtonWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: 10px;
`;

const FilterInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  > img {
    position: absolute;
    right: 270px;
  }
`;

const FilterInput = styled.input`
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  min-width: 100px;
  max-width: 300px;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 40px;
`;

const PaginationContainer = styled(Box)`
  display: flex;
  justify-items: start;
  width: 100%;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
`;

const PaginationControls = styled(Box)`
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;

  > button {
    &:disabled {
      > img {
        opacity: 0.4;
      }
    }
  }
`;

const RecordsInfo = styled(Typography)`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  flex: 1;
`;

const StyledSelect = styled(Select)`
  color: white;
  background-color: #3959A5;
  padding: 2px;
  border-radius: 4px;

  .MuiSelect-select {
    padding: 0px 4px;
    color: white;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #3959A5;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #3959A5;
  }

  .MuiSvgIcon-root {
    color: white;
  }

  .MuiMenuItem-root {
    color: black;
  }

  .MuiMenuItem-root.Mui-selected {
    background-color: #3959A5;
    color: white;
  }
`;

const DataTable = ({
  columns,
  data,
  adjustColumn,
  title,
  filterable = false,
  customBtn,
  onDownloadClick,
  pageSize
}) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize || 5);
  const [filter, setFilter] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilterChange = (search) => {
    setFilter(search);
  };

  const filteredData = data.filter((row) => {
    return Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(filter.toLowerCase())
    );
  });

  const paginatedData = (filterable ? filteredData : data).slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const isWideCell = (columnId) => {
    return adjustColumn.includes(columnId);
  };

  const totalPages = Math.ceil((filterable ? filteredData.length : data.length) / rowsPerPage);

  return (
    <div>
      <Grid container style={{ padding: "10px 0" }}>
        <Grid item xs={(customBtn ? 6 : 12)} container justifyContent={(customBtn ? "left" : "right")}>
          {filterable && (
            <TextField
              placeholder={"Buscar"}
              sx={{ m: 1, width: "25ch", margin: "0"}}
              size="small"
              value={filter}
              onChange={(e) => handleFilterChange(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src="/assets/icono_lupa.svg"
                      alt="icon search"
                      height="24px"
                      width="24px"
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
        {customBtn}
      </Grid>
      <StyledTableContainer>
        <Table>
          <TableHead>
            {title && (
              <TableRow>
                <TitleTableCell colSpan={columns.length}>
                  {title}
                  <IconButtonWrapper>
                    <IconButton color="primary" onClick={onDownloadClick}>
                      <img src="/assets/icono_descargar_marco.svg" alt="icon download" height="20px" width="20px" />
                    </IconButton>
                  </IconButtonWrapper>
                </TitleTableCell>
              </TableRow>
            )}
            <TableRow>
              {columns.map((column) => (
                <CustomTableCell key={column.id}>
                  {column.label}
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <CustomTableCellBody
                    key={column.id}
                    sx={isWideCell(column.id) ? { width: "200px" } : {}}
                  >
                    {row[column.id]}
                  </CustomTableCellBody>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <PaginationContainer>
        <PaginationControls>
          <RecordsInfo variant="body2">
            {`Mostrar `}
            <StyledSelect
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              displayEmpty
            >
              {[5, 10, 25].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledSelect>
            {` registros`}
          </RecordsInfo>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
              shape="rounded"
              renderItem={(item) => (
                <PaginationItem
                  components={{ 
                    previous: (props) => <IconoReg {...props} width="12px" height="12px" />,
                    next: (props) => <IconoNext {...props} width="12px" height="12px" />,
                  }}
                  {...item}
                />
              )}
            />
          </div>
          <div style={{ flex: 1 }}></div>
        </PaginationControls>
      </PaginationContainer>
    </div>
  );
};

export default DataTable;
