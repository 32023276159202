import { toast } from 'react-hot-toast';
import { Alert } from '@mui/material';

export const buildUrlAndClean = (routerOne, routerTwo) => {
    const firstRoute = routerOne.replace("/", "");
    const secondRoute = routerTwo.replace("/", "");
    return `/${firstRoute}/${secondRoute}`;
};

export const ShowMessage = (message, type) => {
    toast.custom(() => {
        return (
            <Alert
                elevation={6}
                variant="filled"
                severity={type}
            >
                {message}
            </Alert>)
    }, { duration: 5000 })
}