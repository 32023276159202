import { CheckCircle } from "@mui/icons-material";
import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import styled from "styled-components";

// Estilos del modal
const StyledDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        borderRadius: "10px",
        padding: "24px",
        width: "500px",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center", 
        boxSizing: "border-box", 
    },
});

const CustomButton = styled(Button)({
    backgroundColor: "#496FEF",
    color: "white",
    fontSize: "14px",
    padding: "6px 20px",
    borderRadius: "5px",
  });

// Component for the modal
export const ConfirmationModal = ({ open, onClose, message }) => {
    return (
        <StyledDialog open={open} onClose={onClose}>
            <Grid container direction="column" alignItems="center" style={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Grid item style={{ marginBottom: 30 }}>
                    <CheckCircle style={{ fontSize: 75, color: "#34c240" }} />
                </Grid>
                <Grid item style={{ textAlign: 'center', color:'black' }}>
                    {message}
                </Grid>
            </Grid>
            <DialogActions style={{ justifyContent: "center" }}>
                <CustomButton variant="contained" onClick={onClose}>
                    Aceptar
                </CustomButton>
            </DialogActions>
        </StyledDialog>
    );
};
