import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable/DataTable";
import { IconButton } from "@mui/material";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { es } from "date-fns/locale/es";
import CustomTemplate from "../../components/CustomTemplate/CustomTemplate";
import TariffSelectModal from "./components/TariffSelectModal";
import CustomButton from "../../components/CustomButtonStyled/CustomButton";
import { ShowMessage } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import ViewModalPdf from "./components/ViewModalPdf";
import { IconStatus } from "./components/IconStatus";
import isEqual from "lodash/isEqual";
import { RUTA_API_BACK } from "../../constans";
import StoreUser from "../../storeUser";

const columns = [
  { id: "id", label: "ID" },
  { id: "dateExtract", label: "Periodo" },
  { id: "dateDocument", label: "Fecha Procesamiento" },
  { id: "originalDocument", label: "Documento original" },
  { id: "statusId", label: "Estatus" },
  { id: "comment", label: "Comentarios" },
  { id: "actions", label: "Ver detalle" },
];

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  "& img": {
    borderRadius: "3px",
  },
}));

const disableIcons = (statusId) => {
  const status = [1, 2, 3, 6];
  return status.includes(statusId);
}


const TariffProcess = () => {
  const [openTariffSelectModal, setOpenTariffSelectModal] = useState(false);
  const [modelTable, setModelTable] = useState([]);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfName, setPdfName] = useState('');
  const [active, setActive] = useState(true);
  const [listTariffs, setListTariffs] = useState([]);

  const history = useNavigate();
  //const [dataAuth, setDataAuth] = useState();

  const storeUser = new StoreUser();

  const fetchTariffs = async () => {
    try {
      //const response = await fetch(`${RUTA_API_BACK}TariffProcessing/processed-tariffs`);
      const session = storeUser.getFromLocalStorage();
      const headers = {
        'Accept': 'application/json'
      };

      if (session?.token) {
        headers['Authorization'] = `Bearer ${session.token}`;
      }

      const response = await fetch(`${RUTA_API_BACK}TariffProcessing/processed-tariffs`, {
        method: 'GET',
        headers: headers
      });

      if (!active) {
        return; // Stop fetching if component is no longer active
      }

      if (response.status === 401) {
        // Mostrar mensaje de sesión expirada
        ShowMessage("Tu sesión ha expirado. Por favor, ingresa de nuevo.", "error");

        // Detener el polling
        setActive(false);

        // Esperar 2 segundos antes de redirigir
        setTimeout(() => {
          window.location.href = "/auth/signin";
        }, 2000);
      } else if (response.status === 502) {
        // Retry connection on timeout
        setTimeout(fetchTariffs, 5000);
      } else if (response.status !== 200) {
        // Handle other errors
        console.error(response.statusText);
        setTimeout(fetchTariffs, 5000);
      } else {
        const tariffList = await response.json();

        setListTariffs(tariffList);

        const models = tariffList.map((tariff) => {
          const period = format(new Date(tariff.year, tariff.month - 1, 1), "MMMM yyyy", { locale: es });
          return {
            id: tariff.tariffProcessId,
            dateExtract: period,
            dateDocument: format(tariff.lastUpdatedDate, "dd-MM-yyyy", {
              locale: es,
            }),
            originalDocument: (
              <CustomIconButton
                size="medium"
                cursor="pointer"
                style={{ cursor: disableIcons(tariff.statusId) ? "not-allowed" : "pointer" }}
                onClick={() => disableIcons(tariff.statusId) ? null : handleOpenPdfModal(tariff.fileUrl, period)}
              >
                <img
                  src="/assets/icono_documento.svg"
                  alt="icon document"
                  width="25px"
                  style={{ filter: disableIcons(tariff.statusId) ? "grayscale(90%)" : "none" }}
                  height="25px"
                />
              </CustomIconButton>
            ),
            statusId: <IconStatus status={tariff.statusId} />,
            comment: tariff.comment,
            actions: (
              <CustomIconButton
                color="primary"
                size="medium"
                style={{ cursor: disableIcons(tariff.statusId) ? "not-allowed" : "pointer" }}
                onClick={() => disableIcons(tariff.statusId) ? null : history(`detalle/${tariff.tariffProcessId}`)}
              >
                <img
                  src="/assets/icono_detalles_grid.svg"
                  alt="icon document"
                  width="25px"
                  style={{ filter: disableIcons(tariff.statusId) ? "grayscale(90%)" : "none" }}
                  height="25px"
                />
              </CustomIconButton>
            ),
          };
        });

        // Check if the data has changed before updating state
        if (!isEqual(models, modelTable)) {
          setModelTable(models);
        }
        // Schedule next fetch
        if (active) {
          setTimeout(fetchTariffs, 5000);
        }
      }
    } catch (error) {
      console.error('Fetch error:', error);
      if (active) {
        setTimeout(fetchTariffs, 5000);
      }
    }
  };

  useEffect(() => {
    fetchTariffs();

    return () => {
      setActive(false);
    };
  }, []);

  const handleSuccess = () => {
    ShowMessage("Tarifa procesada correctamente", "success");
  };

  const handleOpenPdfModal = (url, nameDocument) => {
    setPdfUrl(url);
    setPdfName(nameDocument);
    setOpenPdfModal(true);
  };

  const handleClosePdfModal = () => {
    setOpenPdfModal(false);
    setPdfUrl('');
    setPdfName('');
  };

  return (
    <CustomTemplate title="Procesamiento de tarifas">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box textAlign="right">
            <CustomButton
              variant="outlined"
              onClick={() => setOpenTariffSelectModal(true)}
            >
              Procesar tarifa
            </CustomButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {modelTable.length > 0 && (
            <DataTable
              columns={columns}
              data={modelTable}
              adjustColumn={["originalDocument", "actions"]}
            />
          )}
        </Grid>
      </Grid>
      <TariffSelectModal
        open={openTariffSelectModal}
        onCloseModal={() => setOpenTariffSelectModal(false)}
        onSuccess={handleSuccess}
        data={listTariffs}
      />

      <ViewModalPdf
        open={openPdfModal}
        handleClose={handleClosePdfModal}
        pdfUrl={pdfUrl}
        pdfName={pdfName}
      />
    </CustomTemplate>
  );
};

export default TariffProcess;
