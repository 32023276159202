
import { useMutation, useQuery } from 'react-query';
import { ShowMessage } from '../../../utils/utils';
import axiosServiceV1 from '../../../apiService/axiosService';

const processTariffFetch = async (month, year) => {
  return axiosServiceV1.post(`TariffProcessing/create-tariff-process/${year}/${month}`, {}, { timeout: 600000 });
}

export const getTariffProcessFetch = async () => {
  return axiosServiceV1.get('TariffProcessing/processed-tariffs');
}

export const useGetTariffProcess = (isUpdated) => {
  return useQuery({
    queryKey: ['getTariffProcess', isUpdated],
    queryFn: async () => await getTariffProcessFetch(),
    enabled: true,
  });
};

export const useTariffProcess = () => {
  const mutation = useMutation((data) => processTariffFetch(data.month, data.year));

  const processTariff = async (month, year) => {
      return  await mutation.mutateAsync({ month, year });
  };

  return {
    processTariff,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    isSucess: mutation.isSuccess,
  };
};



