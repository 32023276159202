import React, { useEffect, useState, useMemo } from "react";
import { FiAlertTriangle } from "react-icons/fi";

const Icon = ({ children, ...props }) => {
  const [SvgIcon, setSvgIcon] = useState(null);

  const validProps = useMemo(() => {
    const { bgColor, style, ...restProps } = props;
    return { ...restProps, style: { backgroundColor: bgColor, ...style } };
  }, [props.bgColor, props.style]);

  useEffect(() => {
    const importIcon = async () => {
      if (typeof children === "string") {
        try {
          const importedIcon = await import(`./../icons/${children}.svg`);
          setSvgIcon(<img src={importedIcon.default} {...validProps} />);
        } catch (error) {
          console.error(`Error loading icon: ${error.message}`);
          setSvgIcon(<FiAlertTriangle {...validProps} />);
        }
      } else {
        setSvgIcon(<FiAlertTriangle {...validProps} />);
      }
    };

    importIcon();
  }, [children, validProps]);

  return <>{SvgIcon}</>;
};

export default Icon;

export const IconDetail = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="64" height="64" rx="4" fill="#2756F3" />
      <path fillRule="evenodd" clipRule="evenodd" fill="white" d="M11.9164 13.9167C12.7165 13.1165 13.8018 12.667 14.9334 12.667H23.4667C24.5983 12.667 25.6835 13.1165 26.4837 13.9167C27.2838 14.7168 27.7334 15.8021 27.7334 16.9337V25.467C27.7334 26.5986 27.2838 27.6838 26.4837 28.484C25.6835 29.2841 24.5983 29.7337 23.4667 29.7337H14.9334C13.8018 29.7337 12.7165 29.2841 11.9164 28.484C11.1162 27.6838 10.6667 26.5986 10.6667 25.467V16.9337C10.6667 15.8021 11.1162 14.7168 11.9164 13.9167ZM23.4667 16.9337L14.9334 16.9337V25.467H23.4667V16.9337ZM32 16.9337C32 15.7555 32.9551 14.8003 34.1334 14.8003H51.2C52.3782 14.8003 53.3334 15.7555 53.3334 16.9337C53.3334 18.1119 52.3782 19.067 51.2 19.067H34.1334C32.9551 19.067 32 18.1119 32 16.9337ZM32 25.467C32 24.2888 32.9551 23.3337 34.1334 23.3337H44.8C45.9782 23.3337 46.9334 24.2888 46.9334 25.467C46.9334 26.6452 45.9782 27.6003 44.8 27.6003H34.1334C32.9551 27.6003 32 26.6452 32 25.467ZM11.9164 35.25C12.7165 34.4499 13.8018 34.0003 14.9334 34.0003H23.4667C24.5983 34.0003 25.6835 34.4499 26.4837 35.25C27.2838 36.0502 27.7334 37.1354 27.7334 38.267V46.8003C27.7334 47.9319 27.2838 49.0172 26.4837 49.8173C25.6835 50.6175 24.5983 51.067 23.4667 51.067H14.9334C13.8018 51.067 12.7165 50.6175 11.9164 49.8173C11.1162 49.0172 10.6667 47.9319 10.6667 46.8003V38.267C10.6667 37.1354 11.1162 36.0502 11.9164 35.25ZM23.4667 38.267H14.9334V46.8003H23.4667V38.267ZM32 38.267C32 37.0888 32.9551 36.1337 34.1334 36.1337H51.2C52.3782 36.1337 53.3334 37.0888 53.3334 38.267C53.3334 39.4452 52.3782 40.4003 51.2 40.4003H34.1334C32.9551 40.4003 32 39.4452 32 38.267ZM32 46.8003C32 45.6221 32.9551 44.667 34.1334 44.667H44.8C45.9782 44.667 46.9334 45.6221 46.9334 46.8003C46.9334 47.9785 45.9782 48.9337 44.8 48.9337H34.1334C32.9551 48.9337 32 47.9785 32 46.8003Z" />
    </svg>
  );
};