import { useState } from 'react';
import { DialogTitle, Grid, Dialog, Typography } from '@mui/material';
//import { Dashboard } from '@uppy/react';
import { AttachFile, Help, Clear } from '@mui/icons-material';
import { renderToString } from 'react-dom/server';
//import Uppy from '@uppy/core';
import { UploaderStyled, StyledGenLabel, StyledIcon, PreviewTitle } from './styled.componentes';
import { FilePond, registerPlugin } from 'react-filepond';
//import "@uppy/core/dist/style.css";
//import "@uppy/dashboard/dist/style.css";
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginFileValidateType);

export const Uploader = ({ onFilesUploaded, titlehelp, children }) => {
    //const [uppy] = useState(() => new Uppy());
    const [files, setFiles] = useState([]);
    const [showHelp, setShowHelp] = useState(false);

    const genLabel = () => {
        return renderToString(
            <Grid container justifyContent={"center"}>
                <Grid item xs={12}>
                    <div>Arrastra el archivo en esta area</div>
                    o selecciona tu archivo
                </Grid>
                <StyledGenLabel item xs={8}>
                    <div className='footerlabel'>Selecciona tu archivo...  <AttachFile className='iconfooter' color='#496fef' /></div>
                </StyledGenLabel>
            </Grid>
        )
    }

    const handleDropPage = (e) => {
        //console.log(e);
    }

    const handleUpdateFiles = (fileItems) => {
        setFiles(fileItems.map(fileItem => fileItem.file));
        if (onFilesUploaded) {
            onFilesUploaded(fileItems.map(fileItem => fileItem.file));
        }
    };

    return (
        <>
            <UploaderStyled container>
                {(files.length !== 2) && <StyledIcon item xs={12} textAlign={"left"} onClick={() => setShowHelp(true)} >
                    <Help />
                </StyledIcon>}
                <FilePond
                    files={files}
                    allowMultiple
                    acceptedFileTypes={["application/pdf", "image/jpeg"]}
                    onupdatefiles={handleUpdateFiles}
                    maxFiles={2}
                    //server="/api"
                    name='files'
                    dropOnPage={handleDropPage}
                    labelIdle={genLabel()}
                    fileValidateTypeDetectType={(source, type) =>
                        // Note: we need this here to activate the file type validations and filtering
                        new Promise((resolve, reject) => {
                            // Do custom type detection here and return with promise
                            if (type === 'application/pdf' || type === 'image/jpeg') {
                                resolve(type);
                            }else{
                                reject(type);
                            }
                            // resolve(type);
                        })
                    }
                    imagePreviewHeight={50}
                />
            </UploaderStyled>
            <Dialog open={showHelp} onClose={() => setShowHelp(false)} >
                <DialogTitle>
                <PreviewTitle container>
                    <Grid item xs={1}>
                        <Help className='Helppreview' />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='body2'>{titlehelp}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                    <Clear className='Deletepreview' onClick={() => setShowHelp(false)}/>
                    </Grid>
                </PreviewTitle>
                </DialogTitle>
                <Grid container>
                    {children}
                </Grid>
            </Dialog>
        </>
    )
}