import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TariffProcess from '../views/TariffProcess/TariffProcess';
import TariffProcessDetail from '../views/TariffProcessDetail/TariffProcessDetail';
import { CalculationsContainer } from '../views/Calculations/CalculationsContainer';
import { NewCalculations } from '../views/Calculations/components/NewCalculations';
import { UserManagementContainer } from '../views/UserManagement/UserManagementContainer';


const Router = () => {
  return (
    <Routes>
        <Route path="/tarifa" element={<TariffProcess />} /> 
        <Route path="/tarifa/detalle/:id" element={<TariffProcessDetail />} />
        <Route path="/calculos" element={<CalculationsContainer/>}/>
        <Route path="/calculos/nuevo" element={<NewCalculations/>}/>
        <Route path="/calculos/detalle/:id" element={<NewCalculations/>}/>
        <Route path="/usuarios" element={<UserManagementContainer />} />
    </Routes>
  );
};

export default Router;