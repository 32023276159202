import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  CircularProgress,
  Backdrop,
  Dialog,
  Grid,
  DialogContent,
} from "@mui/material";
import { ModalRoot, ContentModal, FooterActions } from "../styled.components";
import { useTariffProcess } from "../api/TariffProcessApi";
import { ShowMessage } from "../../../utils/utils";
import { styled } from "@mui/material/styles";
import Select from "../../../components/Select/Select";
import { Loader } from "../../../components/Base/Loader";
import { Warning } from "@mui/icons-material";

const CustomButton = styled(Button)({
  backgroundColor: "#496FEF",
  color: "white",
  fontSize: "14px",
  padding: "6px 20px",
  borderRadius: "5px",
});

export const StyledDelete = styled(Grid)`
    padding: 10px;

    .MuiSvgIcon-root{
        font-size: 45px;
        color: #ff922d;
    }
`;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '80%',  // Ajusta el ancho según sea necesario
    maxWidth: '700px',  // Ancho máximo
    minHeight: '250px', // Ajusta la altura mínima según sea necesario
    borderRadius: '16px',
    padding: theme.spacing(2),
    boxShadow: theme.shadows[5],  // Añade sombra para un efecto de elevación
  },
}));

const allMonthOptions = [
  { value: "1", label: "Enero" },
  { value: "2", label: "Febrero" },
  { value: "3", label: "Marzo" },
  { value: "4", label: "Abril" },
  { value: "5", label: "Mayo" },
  { value: "6", label: "Junio" },
  { value: "7", label: "Julio" },
  { value: "8", label: "Agosto" },
  { value: "9", label: "Septiembre" },
  { value: "10", label: "Octubre" },
  { value: "11", label: "Noviembre" },
  { value: "12", label: "Diciembre" },
];

const yearOptions = [
  { value: String(new Date().getFullYear() - 1), label: String(new Date().getFullYear() - 1) },
  { value: String(new Date().getFullYear()), label: String(new Date().getFullYear()) },
];

// Obtén el mes actual y ajusta para formato 1-indexado
const currentMonth = new Date().getMonth() + 1;

// Filtra los meses para incluir solo hasta el mes actual
const monthOptions = allMonthOptions.filter(option => parseInt(option.value) <= currentMonth);


const TariffSelectModal = ({ open, onCloseModal, onSuccess, data }) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [selectedMonth, setSelectedMonth] = useState(String(currentMonth));
  const [selectedYear, setSelectedYear] = useState(String(currentYear));
  const [monthOptions, setMonthOptions] = useState(allMonthOptions);

  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);


  const { processTariff, isLoading } = useTariffProcess();

  useEffect(() => {
    if (parseInt(selectedYear) === currentYear) {
      setMonthOptions(allMonthOptions.filter(option => parseInt(option.value) <= currentMonth));
    } else {
      setMonthOptions(allMonthOptions);
    }
  }, [selectedYear, currentMonth, currentYear]);

  const processTariffAndHandleResult = () => {
    processTariff(selectedMonth, selectedYear)
      .then(() => {
        onCloseModal();
        onSuccess();
      })
      .catch(() => {
        ShowMessage("Ocurrió un error al procesar la tarifa", "error");
      });
  };

  const handleProcess = () => {
    const matchingData = data.find(
      (item) =>
        item.year === parseInt(selectedYear) && item.month === parseInt(selectedMonth)
    );

    if (matchingData && matchingData.isVerified) {
      setShowConfirmCancelModal(true);
    } else {
      processTariffAndHandleResult();
    }
  };

  const handleConfirm = () => {
    setShowConfirmCancelModal(false);
    onCloseModal();
    processTariffAndHandleResult();
  };

  const handleCancel = () => {
    setShowConfirmCancelModal(false);
  };


  return (
    <>
      <Modal open={open} onClose={onCloseModal}>
        <ModalRoot>
          <h3>Procesar Tarifa</h3>
          <ContentModal>
            <Select options={monthOptions} onChange={(value) => setSelectedMonth(value)} defaultValue={selectedMonth} />
            <Select options={yearOptions} onChange={(value) => setSelectedYear(value)} defaultValue={selectedYear} />
          </ContentModal>
          <FooterActions>
            <Button variant="outlined" color="error" onClick={onCloseModal}>
              Cancelar
            </Button>
            <CustomButton
              variant="contained"
              onClick={handleProcess}
              disabled={isLoading}
            >
              Procesar
            </CustomButton>
          </FooterActions>
        </ModalRoot>
      </Modal>

      {showConfirmCancelModal && <StyledDialog open={showConfirmCancelModal} onClose={handleCancel}>
        <StyledDelete container justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
          <Grid item xs={12} marginBottom={3}>
            <Warning fontSize="large" />
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            Existe ya una tarifa procesada y validada correspondiente al periodo seleccionado.
            De proceder con un nuevo procesamiento, se eliminarán de forma definitiva todos los ajustes realizados manualmente.
          </Grid>
          <Grid item xs={12} container columnSpacing={2} textAlign={"center"} justifyContent={"center"} alignItems={"center"} marginTop={3} marginBottom={3}>
            <Grid item xs={4}>
              <Button variant="outlined" color="error" onClick={handleCancel} fullWidth>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={4}>
              <CustomButton
                fullWidth
                variant="contained"
                onClick={handleConfirm}
              >
                Procesar
              </CustomButton>
            </Grid>
          </Grid>
        </StyledDelete>
      </StyledDialog>}

      <Backdrop style={{ zIndex: 10000, color: 'white', textAlign: "center" }} open={isLoading}>
        <Loader size={'30px'} />
      </Backdrop>
    </>
  );
};

export default TariffSelectModal;
